<template>
  <div class="flex flex-col h-screen">
    <NavbarComponent/>
    <main class="mt-20">
      <section class="mb-10 p-3" id="function-section">
        <div class="flex flex-col">
          <h1 class="text-center">{{ $t('Home.function.tagName') }}</h1>
          <p class="text-center mt-7">{{ $t('Home.function.subTagName') }}</p>
          <div class="mt-10 grid grid-cols-1 md:grid-cols-3 custom-border p-1 border-bluse-900">
            <div class="flex flex-col p-3 md:border-r md:border-b">
              <div class="flex justify-center mb-2">
                <img src="./../../assets/golfigo/headphone.png" alt="">
              </div>
              <h4 class="text-center">{{ $t("Home.function.communication.title") }}</h4>
              <p class="text-gray-400">{{ $t("Home.function.communication.description") }}</p>
            </div>

            <div class="flex flex-col p-3 md:border-r md:border-b">
              <div class="flex justify-center mb-2">
                <img src="./../../assets/golfigo/graph.png" alt="">
              </div>
              <h4 class="text-center">{{ $t("Home.function.automation.title") }}</h4>
              <p class="text-gray-400">{{ $t("Home.function.automation.description") }}</p>
            </div>
            <div class="flex flex-col p-3 md:border-b">
              <div class="flex justify-center mb-2">
                <img src="./../../assets/golfigo/grid.png" alt="">
              </div>
              <h4 class="text-center">{{ $t("Home.function.management.title") }}</h4>
              <p class="text-gray-400">{{ $t("Home.function.management.description") }}</p>
            </div>
            <div class="flex flex-col p-3">
              <div class="flex justify-center mb-2">
                <img src="./../../assets/golfigo/kvitto.png" alt="">
              </div>
              <h4 class="text-center">{{ $t("Home.function.receipt.title") }}</h4>
              <p class="text-gray-400">{{ $t("Home.function.receipt.description") }}</p>
            </div>
            <div class="flex flex-col p-3 md:border-l">
              <div class="flex justify-center mb-2">
                <img src="./../../assets/golfigo/folder.png" alt="">
              </div>
              <h4 class="text-center">{{ $t("Home.function.member.title") }}</h4>
              <p class="text-gray-400">{{ $t("Home.function.member.description") }}</p>
            </div>
            <div class="flex flex-col p-3 md:border-l">
              <div class="flex justify-center mb-2">
                <img src="./../../assets/golfigo/chart.png" alt="">
              </div>
              <h4 class="text-center">{{ $t("Home.function.information.title") }}</h4>
              <p class="text-gray-400">{{ $t("Home.function.information.description") }}</p>
            </div>

          </div>
        </div>
      </section>
    </main>
    <FooterComponent/>
  </div>
</template>

<script>
import NavbarComponent from "./components/Navbar.vue";
import FooterComponent from "./components/Footer.vue";
import FaqRenderVue from "./custom/AccordionFaq.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "Landing",
  components: {NavbarComponent, FooterComponent, FaqRenderVue},
  data() {
    return {
      subscriptions: {
        accounting_programs: [],
        packages: [],
        payment_plans: [],
      },
      copyPackages: [],
      faq: {results: []},
      screenshots: {},
      subForm: {
        accounting_programs: "",
        payment_plans: "",
      },
      cards: [1, 2, 3, 4, 5, 6, 7, 8],
    };
  },
  mounted() {
    this.getLandingPackages();
    this.getLandingFaq();
    this.getLandingScreenshot();
  },
  methods: {
    prev() {
      this.$refs.myCarousel.prev();
    },
    next() {
      this.$refs.myCarousel.next();
    },
    getLandingPackages() {
      this.$useJwt.getLandingPackages().then((res) => {
        if (res.status === 200) {
          this.subscriptions = {
            ...res.data.data,
            packages: res.data.data.packages.sort(
                (a, b) =>
                    parseFloat(a.monthly_price) - parseFloat(b.monthly_price)
            ),
          };
          this.copyPackages = [
            ...res.data.data.packages.sort(
                (a, b) =>
                    parseFloat(a.monthly_price) - parseFloat(b.monthly_price)
            ),
          ];
        }
      });
    },
    getLandingFaq() {
      this.$useJwt.customLanding({URL: "/faq", method: "get"}).then((res) => {
        if (res.status === 200) {
          this.faq = res.data;
        }
      });
    },
    getLandingScreenshot() {
      this.$useJwt
          .customLanding({URL: "/dashboard_screenshots", method: "get"})
          .then((res) => {
            if (res.status === 200) {
              this.screenshots = res.data.results;
            }
          });
    },
    getStartedSubscription(pack) {
      if (this.subForm.payment_plans === '') {
        this.subForm.payment_plans = 'Årsvis';
      }
      if (this.subForm.accounting_programs === "") {
        this.popup(
            "Bokföringsprogram är obligatoriskt",
            "danger",
            this.$t("Message.Failed"),
            "AlertTriangleIcon"
        );
        return;
      } else if (this.subForm.payment_plans === "") {
        this.popup(
            "Betalningsperiod är obligatoriskt",
            "danger",
            this.$t("Message.Failed"),
            "AlertTriangleIcon"
        );
        return;
      } else {
        this.subForm.package = pack;
        localStorage.setItem("__getStarted__", JSON.stringify(this.subForm));
        this.$router.push({name: "register"});
      }
    },
    onChangeAccountingProgram(_) {
      this.calculateSubscription();
    },
    onChangePaymentplan(_) {
      this.calculateSubscription();
    },
    calculateSubscription() {
      const plan = this.subForm.payment_plans
          ? this.subForm.payment_plans.monthly_add_on
          : 0;
      const price = this.subForm.accounting_programs
          ? this.subForm.accounting_programs.monthly_add_on
          : 0;
      const packages = this.copyPackages.map((el) => {
        return {
          ...el,
          monthly_price: parseFloat(el.monthly_price + price + plan),
        };
      });
      this.subscriptions = {
        ...this.subscriptions,
        packages: packages,
      };
    },
    popup(message, variant, title, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: icon,
          variant: variant,
          text: message,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.support-gradient {
  font-style: normal;
  font-weight: 794;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  background: linear-gradient(266.01deg, #0b0875 12.33%, #00d090 113.67%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
</style>
